.wave-container {
  background: var(--bs-white);
  height: 100%;
}
.wave-container > svg {
  display: block;
}
.wave-container {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.wave-container-reverse {
  transform: rotate(180deg);
}
